.basket {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
#header {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 99;
}
.section_item {
  position: absolute;
  top: 50%;
  width: 70%;
}
.section {
  background-image: url("../src/assets/Image/seiko.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  position: relative;
  max-height: 800px;
  min-height: 800px;
}
.logo {
  width: 100px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pages {
  display: flex;
  gap: 30px;
  align-items: center;
}
.pages p {
  color: #ffff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.aroow {
  width: 15px;
}
.btns {
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #000080;
  color: #fff;
  gap: 10px;
}
.basket_item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.section h1 {
  font-size: 80px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 12px;
}
.section p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.guys {
  width: 50%;
}
.cards {
  border-radius: 20px;
  padding: 20px;
  max-width: 500px;
  min-width: 500px;
  background-color: #000080;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.card-item {
  display: flex;
  gap: 20px;
}

.cards h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
}
.cards p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.mission {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.mission h4 {
  width: 50%;
  font-size: 28px;
  font-weight: 400;
  color: #000080;
  line-height: 1;
}

.wellness {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.wellness img {
  width: 30%;
  object-fit: contain;
  object-position: center;
}
.wellness h3 {
  width: 60%;
  color: #000080;
  font-weight: 400;
  font-size: 25px;
  line-height: 1;
}
.lines {
  background-image: url("./../src/assets/Image/lines.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  margin-top: 100px;
  max-height: 600px;
  min-height: 600px;
  padding-top: 50px;
}
.lines_about {
  background-image: url("./../src/assets/Image/about.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  margin-top: 100px;
  padding-top: 50px;
  display: flex;
  max-height: 600px;
  min-height: 600px;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 100px;
}
.fish {
  background-image: url("./../src/assets/Image/fish.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 80vh;
  margin-top: 100px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cell {
  border-radius: 20px;
  border: 2px solid #fff;
  padding: 20px;
  width: 290px;
  backdrop-filter: blur(10px);
  margin-left: 200px;
}
.cell h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.tops {
  margin-top: 60px;
  margin-left: 50px;
}
.lefts {
  margin-left: 500px;
  margin-top: -130px;
}

.japones h3 {
  color: #000080;
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
}
.live-japon {
  width: 55%;
  display: flex;
  margin-top: 20px;
  gap: 30px;
}
.live-japon p {
  color: #000;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
}
.famili {
  width: 240px;
  height: 170px;
  border-radius: 10px;
}
.famil {
  width: 50%;
}
.famile_item {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: flex-start;
  margin-top: 100px;
}

.opportunities {
  background-color: #000080;
  color: #fff;
  padding: 20px;
}

.opportunity-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.opportunity-item {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  border-top: 1px solid #fff;
  padding-top: 15px;
  padding-left: 30px;
}

.number {
  font-size: 20px;
  margin-right: 15px;
}

.description {
  font-size: 25px;
  width: 85%;
  line-height: 1;
  font-weight: 400;
}
.global {
  background-image: url("././assets/Image/global.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  max-height:800px;
  min-height: 800px;
  margin-top: 100px;
  position: relative;
}
.part {
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 20px;
  display: inline-block;
  position: absolute;
  left: 45%;
  top: 50%;
  width: 24%;
}
.part h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
}
.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.flexs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.product {
  color: #000 !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}
.welln {
  width: 20%;
  margin-bottom: 20px;
}
.buts img {
  width: 70% !important;
}
.buts {
  border-radius: 20px;
  border: 1px solid #000080;
  min-height: 500px;
  max-height: 550px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 495px;
  min-width: 495px;
}
.Glucosamine {
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000080;
  color: #fff;
  max-width: 495px;
  min-width: 495px;
}
.colllegan h1 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 12px;
}
.hair p {
  margin-bottom: 15px;
}
.card-items {
  display: flex;
  gap: 20px;
}
.row {
  flex-direction: row-reverse;
}
.tops {
  margin-top: 100px;
}
.coffee {
  background-image: url("./assets/Image/bac.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 20px;
  gap: 20px;
}
.protein {
  width: 260px;
  height: 400px;
}
.keto {
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  max-width: 500px;
  min-width: 500px;
}
.keto h1 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 400;
}
.keto h4 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
}
.keto p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.cofe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
}

.vitamin {
  background-image: url("./assets/Image/vitamin.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  border-radius: 20px;
  position: relative;
  padding: 20px;
  max-height: 600px;
  min-height: 600px;
}
.vitamin h1 {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 18px;
}
.salsm {
  position: absolute;
  top: 17%;
  left: 34%;
  width: 27%;
}
.vitamin p {
  color: #fff;
}
.texts {
  display: flex;
  justify-content: space-between;
}
.width {
  width: 35%;
}
.width p {
  margin-bottom: 50px;
}
.lop {
  margin-top: 100px;
}
.top1 {
  margin-left: 60px;
}
.top2 {
  margin-left: 80px;
  margin-top: 60px;
}
.top3 {
  margin-left: 160px;
  width: 80%;
  margin-top: -20px;
}
.left1 {
  margin-top: -30px;
}
.left2 {
  margin-top: 90px;
  margin-left: 20px;
}
.left3 {
  margin-top: 80px;
  margin-left: 30px;
}
.left4 {
  margin-left: -80px;
  margin-top: 80px;
}
.sticy {
  position: sticky;
  top: 0;
  z-index: 99;
  margin-top: 150px;
  background-color: #fff;
}
.sticytop {
  position: sticky;
  top: 63px;
  z-index: 99;
  background-color: #fff;
  margin-bottom: 10px;
  margin-top: 150px;
}
.botm {
  margin-bottom: 100px;
}
.business {
  background-image: url("./assets/Image/Group.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  padding: 20px;
  position: relative;
  margin-top: 200px;
  max-height: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.business-section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.business p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.business h1 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.business h4 {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.pro {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #fff;
  backdrop-filter: blur(30px);
}
.pro h3 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.pro_item {
  display: flex;
  gap: 20px;
}
.butss {
  width: 100%;
}
.news_item {
  border-radius: 20px;
  max-width: 290px;
  min-width: 290px;
  background-color: #ebe9e9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #000;
  padding-top: 20px;
  padding-bottom: 20px;
}
.meeting {
  width: 40%;
}
.border {
  border: 1px solid #000;
  color: #000;
  margin-top: 12px;
}
.meeting h3 {
  font-size: 22px;
  font-weight: 400;
  color: #1e1e1e;
  margin-bottom: 10px;
}
.form {
  background-image: url("./assets/Image/form.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  min-height: 600px;
  max-height: 600px;
  padding: 20px;
  border-radius: 20px;
  margin-top: 100px;
  display: flex;
  color: #fff;
  justify-content: space-between;
}

.form p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.form h1 {
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
}
.sale h2 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
}
.sale_item {
  padding: 20px;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  border: 1px solid #fff;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sale {
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
}
.name {
  display: flex;
  gap: 10px;
}
.btnsd {
  background-color: #fff;
  border: none;
  display: flex;
  padding: 7px 7px 7px 20px;
  border-radius: 20px;
  gap: 30px;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}
.arr {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
}
.email {
  position: relative;
}
.footer {
  margin-top: 100px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.logo_footer {
  width: 180px;
  height: 100px;
  object-fit: contain;
}
.contect {
  display: flex;
  gap: 30px;
}
.contect h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.contect p {
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
.facebook {
  width: 30px;
  height: 30px;
}
.icons {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}
.Maxdev {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.term {
  display: flex;
  gap: 20px;
}
.Maxdev p {
  color: #64666c;
  font-size: 14px;
  font-weight: 400;
}
#aboutUs {
  background-color: #000080;
  position: relative;
  color: #fff;
  max-height: 400px;
  min-height: 370px;
  padding-top: 100px;
}
.back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.title-pages {
  font-size: 35px;
  font-weight: 400;
  margin-top: 150px;
  text-transform: uppercase;
}
.aboutUs {
  margin-top: 100px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin-left: auto;
}
.aboutUs h3 {
  color: #000080;
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
}
.text_about {
  display: flex;
  gap: 30px;
}
.text_about p {
  width: 35%;
  font-size: 16px;
  font-weight: 400;
}
.images {
  width: 100%;
  max-height: 600px;
  min-height: 600px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.call_About {
  width: 70%;
  margin-top: 100px;
}

.call_About h1 {
  font-size: 38px;
  text-transform: uppercase;
  font-weight: 400;
  color: #000080;
  margin-bottom: 20px;
}
.call_About h1 span {
  color: #b0810e;
}
.lines_about h3 {
  color: #fff;
  width: 50%;
}
.fish p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.fish h1 {
  font-size: 35px;
  font-weight: 400;
  color: #fff;
  width: 50%;
  margin-top: 12px;
  margin-bottom: 12px;
}
.fish h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
}
.last {
  width: 100%;
  max-width: 650px;
  margin-top: 30px;
  min-width: 650px;
  min-height: 588px;
  max-height: 588px;
  border-radius: 10px;
}
.list {
  position: relative;
}
.nevember {
  position: absolute;
  top: 130px;
  left: 30px;
  width: 80%;
}

.nevember p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.nevember h1 {
  font-size: 35px;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  margin-top: 12px;
  margin-bottom: 12px;
}
.nevember h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: underline;
}
.vied{
  max-height: 500px;
  min-height: 500px;
}
.list_item {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  border-top: 1px solid #d9d9d9;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}
.last_img {
  max-width: 350px;
  min-width: 350px;
  max-height: 200px;
  min-height: 200px;
  border-radius: 10px;
}
.contactd{
  width: 200px;
}
.contactd a{
  color: #9b9b9b;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}
.lasy_items {
  height: auto;
}
.lasy_items p {
  font-size: 14px;
  color: #000080;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}
.lasy_items h2 {
  margin-bottom: 5px;
  color: #000080;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;

}
.lasy_items h3 {
  text-decoration: underline;
  color: #000080;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.video-section {
  position: relative;
  text-align: left;
  margin-top: 70px;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
}

.video-image {
  width: 100%;
  min-height: 700px;
  max-height: 700px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: transparent;
  font-size: 32px;
  padding: 10px 20px;
  cursor: pointer;
}
.video-info {
  position: absolute;
  top: 50px;
  left: 120px;
  color: #fff;
  width: 40%;
}
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-wrapper {
  position: relative;
  width: 80%;
  max-width: 800px;
}

.video-wrapper video {
  width: 100%;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.well {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-left: auto;
  width: 30%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.proti {
  width: 50%;
}
.protein_product {
  width: 240px;
  height: 280px;
  background-color: #f5f2f2;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.card_product h3 {
  width: 240px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #111111;
}
.card_product p {
  color: #9b9b9b;
  font-size: 12px;
}
.card_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.salal {
  margin-top: 100px;
}
.detail {
  margin-top: 10px;
  margin-bottom: 10px;
  background: linear-gradient(
      93.92deg,
      #f9bc2e 0%,
      #e29a1e 13.29%,
      #ffcd38 30.34%,
      #f0af30 73.17%,
      #e29a1e 83.8%,
      #ffcd38 96.43%,
      #dd931a 100.4%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  width: 100%;
  height: 2px;
}
.butil {
  background-color: #f8f9fa;
  max-width: 500px;
  min-width: 500px;
  max-height: 550px;
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.butil img {
  width: 70%;
}

.sikoe h1{
  font-size: 25px;
  font-weight: 400;
  color: #111111;
}
.sikoe{
  display: flex;
  border-bottom: 2px solid #e29a1e;
  padding-bottom: 5px;
}
.sikoe img{
  width: 65px;
  height: 65px;
}
.detail_item{
  display: flex;
  gap: 20px;
}
.peremenie{
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  text-transform: uppercase;
  margin-top: 5px;
}
.ago_days{
  font-size: 14px;
  color: #9B9B9B;
  margin-top: 5px;
  font-weight: 400;
}
.line_bottom{
  border-bottom: 1px solid #e0e2e3;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 80%;
}
.ageo{
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 36px;
}
.flesdd{
  display: flex;
  width: 44%;
  justify-content: space-between;
}
.borders{
  color: #fff;
  background-color: #000;
  width: 100%;
}
.borderred{
  border: 1px solid #000;
color: #000;
width: 100%;
}
.borders:hover{
  background-color: rgb(0, 0, 128);
  cursor: pointer;
  color: #fff;
  border: none;
  transition: all 0.5s ease-in-out;
}
.borderred:hover{
  background-color: rgb(0, 0, 128);
  cursor: pointer;
  color: #fff;
  border: none;
  transition: all 0.5s ease-in-out;
}
.buttons{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.hover_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#000080;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; 
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; 
}

.protein_product:hover .hover_overlay {
  opacity: 1; 
}
.sikoe_item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scrin{
  width: 30% ;
}
.umen{
  margin-top: 20px;
}
.umen p{
  color: #111111;
  font-size: 18px;
  font-weight: 400;
  margin-top: 12px;
}
.details_brain{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  margin-top: 20px;
  gap: 50px;
  padding: 100px;
}
.sent_too{
  font-size: 25px;
  font-weight: 300;
  color: #111111;
}
.all_product{
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-decoration:underline;
}
.all_detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  cursor: pointer;
}
.topxsds{
  margin-top: 30px;
}
.littsg{
  display: flex;
  gap: 20px;
}


.color{
  color: #fff;
}
.cash{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
}
.item-quantity{
  display: flex;
  align-items: center;
  gap: 10px;
}
button{
  cursor: pointer;
}
.tio{
  margin-top: 20px;
}
.cash img{
  width: 130px;
  height: 45px;
  object-fit: contain;
  object-position: center;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 15px 0;
}

.quantity-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-display {
  font-size: 18px;
  min-width: 30px;
  text-align: center;
}

/* В конец файла добавляем стили для кастомизации toast */
.Toastify__toast {
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.Toastify__toast--success {
  background: #4caf50;
  color: white;
}

.Toastify__close-button {
  color: white;
}

.Toastify__progress-bar {
  background: rgba(255, 255, 255, 0.7);
}

.basket-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #FF3B30;
  color: white;
  border-radius: 50%;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;
  padding: 0 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.social-icon:hover{
  color: #000080;
}
.news-detail{
  /* height: 60vh; */
  border-radius: 20px;
  height: 520px;
  object-fit: cover;
  object-position: center;
  width: 50%;
}
.news-det{
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  text-align: center;
  border-bottom: 1px solid #9B9B9B;
  padding-bottom: 20px;
}
.komflex h1{
  font-size: 35px;
  font-weight: 400;
  color: #000;
  line-height: 1;
  margin-bottom: 20px;
}
.pred{
  width: 50%;
  margin-top: 20px;
}