@media (max-width: 768px) {

  .section_item {
    top: 40%;
    width: 90%;
  }
  .guys {
    width: 100%;
  }
  .text_title p {
    display: none;
  }
  .text_title {
    margin-top: 30px;
  }
  .text_title h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .cards h3 {
    font-size: 18px;
  }
  .section h1 {
    font-size: 40px;
  }
  .sticy{
    margin-top: 50px;
  }
  .cards {
    height: 300px;
    max-width: none;
    min-width: 100%;
  }
  .section {
    height: 50vh;
    max-width: none;
    min-height: 100%;
  }
  .section p{
    font-size: 20px;
  }
  .mission h4 {
    font-size: 18px;
  }
  .mission h4 {
    width: 100%;
  }
  .card-item {
    flex-direction: column;
  }
  .mission {
    flex-direction: column;
  }
  .text_title h1 span {
    font-size: 24px;
  }
  .wellness img {
    display: none;
  }
  .wellness h3 {
    width: 100%;
    font-size: 18px;
  }
  .cell {
    display: none;
  }
  .lines {
    display: none;
  }
  .japones h3 {
    font-size: 18px;
  }
  .live-japon {
    width: 100%;
    flex-direction: column;
  }
  .card-items {
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid #000888 ;
  }
  .Glucosamine {
    width: 100%;
    max-width: none;
    min-width: 100%;
  }
  .opportunities {
    padding: 20px 0;
  }
  .description {
    width: 80%;
    font-size: 16px;
  }
  .global {
    height: 30vh;
    max-height: none;
    min-height: 100%;
  }
  .welln{
    display: none;
  }
  .Glucosamine{
    border-radius: 0;
  }
  .buts {
    width: 100%;
    max-width: none;
    min-width: 100%;
    max-height: none;
    border: none;
    min-height: 100%;
  }
  .buts img {
    width: 60% !important;
  }
  
  .title-pages {
    margin-top: 50px;
    font-size: 24px;
  }
  #aboutUs {
    min-height: 200px;
    padding: 70px 0 30px;
  }
  .aboutUs {
    width: 100%;
  }
  .aboutUs h3 {
    font-size: 24px;
  }
  .text_about {
    flex-direction: column;
  }
  .text_about p {
    width: 100%;
  }
  .images {
    margin-top: 50px;
    width: 100%;
    height: 50vh;
    margin-bottom: 50px;
  }
  .container {
    width: 90%;
  }
  .call_About h1 {
    font-size: 24px;
  }
  .call_About {
    width: 100%;
  }
  .lines_about {
    justify-content: center;
    height: 50vh;
  }
  .lines_about h3 {
    width: 100%;
  }
  .famile_item {
    flex-direction: column;
  }
  .famili {
    width: 100%;
  }
  .famil {
    width: 100%;
  }
  .card_grid {
    grid-template-columns: 1fr;
  }
  .protein_product {
    width: 100%;
  }
  .detail_item {
    flex-direction: column;
  }
  .buttons {
    flex-direction: column;
  }
  .line_bottom {
    width: 100%;
  }
  .flesdd {
    width: 100%;
  }
  .butil img {
    width: 80%;
  }
  .sikoe {
    margin-bottom: 10px;
  }
  .butil {
    max-width: none;
    min-width: 100%;
    min-height: 400px;
  }
  .scrin {
    width: 50%;
  }
  .basket{
    width: 30px;
    height: 30px;
  }
  .details_brain {
    padding: 20px;
    flex-direction: column-reverse;
  }
  .btns{
  }
  .fish {
    padding: 20px;
    height: 40vh;
  }
  .fish h1 {
    width: 100%;
    font-size: 28px;
    line-height: 1;
  }
  .littsg {
    flex-direction: column;
  }
  .nevember h1 {
    font-size: 24px;
    line-height: 1;
  }
  .nevember h3{
    font-size: 16px;
  }
  .footer {
    flex-direction: column;
  }
  .contect {
    flex-direction: column;
  }
  .nevember {
    top: 55px;
    width: 80%;
    left: 20px;
  }
  .play-button{
    display: none;
  }
  .last {
    width: 100%;
    max-width: none;
    min-width: 100%;
    max-height: none;
    min-height: 100%;
  }
  .business h1 {
    font-size: 28px;
  }
  .pro_item {
    display: none;
  }
  .news{
    flex-direction: column-reverse;
  }
  .business{
    height: 45vh;
  }
  .border{
    display: none;
  }
  .meeting{
    width: 100%;
  }
  .meeting h3{
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
  }
  .news_item{
    max-width: none;
    min-width: 100%;
    padding: 0;
    border-radius: 8px;
  }
  .news{
    border: none;
  }
  .butss{
    width: 100%;
  }
  .part h3{
    font-size: 18px;
  }
  .part{
    left: 28%;
    padding: 10px;
    border-radius: 10px;
    top: 30%;
    width: 47%;
  }
  .form{
    justify-content: center;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    max-height: none;
    background-size: cover;
  }
  .sale_item{
    width: 100%;
    gap: 90px;
  }
  .form p{
    display: none;
  }
  .form h1{
    display: none;
  }
  .sale h2{
    font-size: 24px;
  }
  .name{
    flex-direction: column;
    gap: 0;
  }
  .busket-type{
    display: none;
  }
  .busket-quantity{
    display: none;
  }
  .busket-price{
    display: none;
  }
  .busket-header{
    display: flex;
    justify-content: space-between;
  }
  .busket-footer{
    width: 100%;
    justify-self: center;
  }
  .busket-item {
    display: grid; 
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 30px;
}
.news-det{
  flex-direction: column;
  gap: 20px;
}
.news-detail{
  width: 100%;
  height: 100%;
}
  .remove-item{
    display: none;
  }
  .item-name{
    width: 200px;
  }
  .pred{
    width: 100%;
  }
  .komflex h1{
    font-size: 24px;
    margin-bottom: 15px;
  }
}

