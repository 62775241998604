#busket {
  background-color: #000080;
  min-height: 100vh;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.busket {
  max-width: 1200px;
  margin: 0 auto;
  color: white;
}

.busket-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 40px;
  padding: 20px 0;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.busket-close {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.busket-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 40px;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.item-quantity select {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px 10px;
  border-radius: 4px;
}

.item-quantity select option {
  background: #000080;
}

.busket-footer {
  margin-top: 20px;
  width: 50%;
  display: flex;    
  justify-self: end;
  flex-direction: column;
}

.delivery-info {
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
}

.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.pay-button {
  background: white;
  border: none;
    color: #000;
  margin-bottom: 20px;
  cursor: pointer;
}

.payment-methods {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.payment-method{
    display: flex;
    flex-direction: column;
    gap: 20px; 
}
.payment-methods img {
  height: 30px;
  object-fit: contain;
}

.remove-item {
  background: none;
  border: none;
  color: #999;
  font-size: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.remove-item:hover {
  color: #ff0000;
}

.item-type {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  border-radius: 8px;
}

.empty-cart-content {
  text-align: center;
  padding: 2rem;
}

.empty-cart-content i {
  font-size: 4rem;
  color: #fff;
  margin-bottom: 1rem;
}

.empty-cart-content h2 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.empty-cart-content p {
  color: #fff;
  margin-bottom: 1.5rem;
}

.empty-cart-content .btn {
  background-color: #007bff;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.empty-cart-content .btn:hover {
  background-color: #0056b3;
} 