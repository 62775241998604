.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  z-index: 1000;
}

.burger-menu span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.burger-menu.open span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.burger-menu.open span:nth-child(2) {
  opacity: 0;
}

.burger-menu.open span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .burger-menu {
    display: flex;
  }

  .pages {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background-color: #000888;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    transition: right 0.3s ease-in-out;
    z-index: 999;
  }

  .pages.menu-open {
    right: 0;
  }

  .pages .page {
    margin: 0;
    font-size: 1.2rem;
    color: white;
  }

  .basket_item {
    z-index: 1000;
  }
}

@media screen and (min-width: 769px) {
  .pages {
    display: flex;
    align-items: center;
  }

  .pages .page {
    margin: 0 15px;
  }
} 