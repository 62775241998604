.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background-color: #000080;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  color: white;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  color: white;
  font-size: 34px;
  cursor: pointer;
}

.total-amount {
  margin: 20px 0;
  font-size: 18px;
}


.form-group input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.payment-methods h3 {
  margin-bottom: 15px;
}

.payment-options {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.payment-options label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.payment-options img {
  height: 30px;
  object-fit: contain;
}

.submit-button {
  width: 100%;
  margin-top: 20px;
  background: white;
  color: #000080;
}

.Toastify__toast-container {
  z-index: 10000;
}

.Toastify__toast {
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.Toastify__toast--success {
  background: #000080;
  color: white;
}

.Toastify__toast--error {
  background: #f44336;
  color: white;
}

.Toastify__close-button {
  color: white;
}

.Toastify__progress-bar {
  background: rgba(255, 255, 255, 0.7);
} 