* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 80%;
}
img {
  object-fit: cover;
  object-position: center;
}
.btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
}

.text_title{
  margin-top: 100px;
}
.text_title p{
  font-size: 16px;
  font-weight: 400;
  color: #000080;
}
.text_title h1{
  font-size: 40px;
  font-weight: 500;
  color: #000080;
  margin-top: 28px;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.text_title h1 span{
  font-size: 40px;
  font-weight: 500;
  color: #B0810E;
  margin-top: 28px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

input {
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 13px 20px;
  background: transparent;
  outline: none;
  color: #fff;
  width: 100%;
  margin-bottom: 18px;
}
input::placeholder{
  color: #fff;
}
.page{
  color: #fff;
  text-decoration: none;
}